import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  registerConfirmForm: FormGroup;
  registerProc: string;
  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
      mobile: ['', Validators.required],
    });
    this.registerConfirmForm = this.formBuilder.group({
      code: ['', Validators.required],

    });
    this.registerProc = 'undefined';
  }
  get regForm() { return this.registerForm.controls; }
  get regConfForm() { return this.registerConfirmForm.controls; }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }
    this.authService.Register(this.regForm.email.value, this.regForm.password.value, this.regForm.mobile.value).subscribe(
      (data) => {
        this.registerProc = 'codesent';
      },
      (err) => {
        console.log(err);
        this.message = 'Registration Error has occurred';
      }
    );

  }

  onConfirmCodeSubmit() {
    this.authService.confirmAuthCode(this.regConfForm.code.value).subscribe(
      (data) => {
        // this._router.navigateByUrl('/');
        this.registerProc = 'success';
      },
      (err) => {
        console.log(err);
        this.message = 'Confirm Authorization Error has occurred';
      });
  }
}
