import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  forgotConfirmForm: FormGroup;
  forgotProc: string;
  registerForm: FormGroup;
  message: string;
  submitted = false;
  submittedCode = false;
  enteredUsername: string;
  fromPath: string;
  username: string = localStorage.getItem('ec2currentUsername');

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.forgotConfirmForm = this.formBuilder.group({
      code: ['', Validators.required],
      password: ['', [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      repassword: ['', [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]]
    });

    this.registerForm = this.formBuilder.group({ });
    this.forgotProc = 'undefined';

    this.route.paramMap.subscribe(params => {
      this.fromPath = params.get('from');
      if ( this.fromPath === 'dashboard') {
        this.forgotForm.controls['username'].setValue(this.username);
      }
    });
  }
  get forgotFunForm() { return this.forgotForm.controls; }
  get forgotCodeFunForm() { return this.forgotConfirmForm.controls; }


  onEmailSubmit() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.authService.forgotPassword(this.forgotFunForm.username.value).subscribe(
      (data) => {
        this.message = null;

        this.forgotProc = 'codesent';
        this.enteredUsername = this.forgotFunForm.username.value;
        console.log(data);
      },
      (err) => {
        console.log(err);
        this.message = err.message;
      }
    );

  }

  onConfirmCodeSubmit() {
    this.submittedCode = true;
    if (this.forgotCodeFunForm.invalid) {
      return;
    }
    if (this.forgotCodeFunForm.password.value !== this.forgotCodeFunForm.repassword.value) {
      this.message = 'Password should match';
      return;
    }
    if (this.forgotCodeFunForm.password.value === '' || this.forgotCodeFunForm.repassword.value === '') {
      this.message = 'Password should not empty';
      return;
    }
    this.authService.confirmForgotPasswordCode(this.enteredUsername, this.forgotCodeFunForm.code.value,
      this.forgotCodeFunForm.password.value).subscribe(
      (data) => {
        this.forgotProc = 'success';
        console.log(data);
      },
      (err) => {
        console.log(err);
        this.message = 'Forgot Password Error has occurred';
      }
    );
  }
}
