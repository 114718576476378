export const environment = {
  production: true,
  // apiUrl: 'https://uc5uu97mf5.execute-api.ap-south-1.amazonaws.com/Prod/',  //VibhumIT
  // apiUrl: 'https://xdzax7ik04.execute-api.eu-west-1.amazonaws.com/Prod/',     // Sabbas Test Account

  // // Sabbas Test
  // UserPoolId: 'eu-west-1_nWxZN6pV0',
  // ClientId: '3skhpa6lq3f66elj70639op4od',
  // apiUrl: 'https://zd45lek94h.execute-api.eu-west-1.amazonaws.com/Prod/', //  Sabaas Auth
  // webserviceUrl: 'https://ueuafp2xpk.execute-api.eu-west-1.amazonaws.com/Prod/api/',


  // // VibhumIT
  // UserPoolId: 'ap-south-1_EYJiI5uP2',
  // ClientId: '7qf17q7b8bigmjb76je25pnnih',
  // apiUrl: 'https://da4wcpnmek.execute-api.ap-south-1.amazonaws.com/Prod/', //  Test Auth VibhumIT
  // // apiUrl: 'https://mmby26unj1.execute-api.us-east-1.amazonaws.com/Prod/', // US-N.Virginia
  // webserviceUrl: 'https://rzcg86uc31.execute-api.ap-south-1.amazonaws.com/Prod/api/',

  // // Sabbas @ nproxx Test
  // UserPoolId: 'eu-west-1_Pw6Fn0RFa',
  // ClientId: 'jqo9kfkce3usvpd81j7h98nar',
  // apiUrl: 'https://bc44zs3y4b.execute-api.eu-west-1.amazonaws.com/Prod/', //  Sabaas Auth
  // webserviceUrl: 'https://qg5ruiswxc.execute-api.eu-west-1.amazonaws.com/Prod/api/',

  // Sabbas @ iqibt Test
  UserPoolId: 'eu-west-1_Tu2ES7CGU',
  ClientId: '1msr17de2s05bkg6lcc1unm6us',
  apiUrl: 'https://hq5k124ahe.execute-api.eu-west-1.amazonaws.com/Prod/', //  Sabaas Auth
  webserviceUrl: 'https://o55zfck5d8.execute-api.eu-west-1.amazonaws.com/Prod/api/',

  // For AutoScale
  // apiUrl: 'https://phogs97c0a.execute-api.eu-west-1.amazonaws.com/Prod/'

  // apiUrl: 'https://1i25hq21v1.execute-api.ap-south-1.amazonaws.com/Prod/' // VibhumIT Autoscale
};
