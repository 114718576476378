import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  message: string;
  loading = false;
  submitted = false;
  loggedIn: boolean;
  // @Input() loginStatus : string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const userDetails = this.authService.getLoggedUser().subscribe(result => {
      this.loggedIn = result;
      if (result) {
        this.router.navigate(['/starter']);
      }
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService.Login(this.f.username.value, this.f.password.value).subscribe((data) => {
      console.log(data);
      this.loading = false;

      localStorage.setItem('ec2currentUser', 'true');
      localStorage.setItem('ec2currentUsername', this.f.username.value);
      this.router.navigateByUrl('/');
    },
      (err) => {
        this.message = err.message;
        this.loading = false;
        console.log(err);
      });
    // if (this.f.username.value == "nav" && this.f.password.value == "nav") {
    //   localStorage.setItem('ec2currentUser','true');
    //   this.isUserLoggedIn$.next(true);
    //   this.router.navigate(['starter']);

    // }
    // else{
    //   this.isUserLoggedIn$.next(false);
    //   this.message = "Invalid Details";
    // }
  }

}
