import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ScheduleComponent } from './starter/schedule/schedule.component';

export const Approutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            { path: '', redirectTo: '/starter', pathMatch: 'full', },
            { path: 'starter', loadChildren: './starter/starter.module#StarterModule', canActivate: [AuthGuard] },
            { path: 'component', loadChildren: './component/component.module#ComponentsModule' },
            // { path: 'login', component:LoginComponent },
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forgotPassword/:from', component: ForgotPasswordComponent },
    {
        path: '**',
        redirectTo: '/login'
    }];


