import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class StarterService {
    apiURL: string = environment.apiUrl;
    webserviceURL: string = environment.webserviceUrl;
    token: string;
    constructor(private http: HttpClient,
        private authService: AuthorizationService) { }
    getInstancesList() {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        // console.log(userDetails);
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token);
        // console.log(httpHeaders);
        // console.log(httpHeaders.get('authorization'));
        return this.http.get<any>(`${this.apiURL}instances/`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    startInstance(instanceId: string) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        // console.log(userDetails);
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token);
        // let httpHeaders = new HttpHeaders()
        //     .set('Content-Type','application/json');
        return this.http.get<any>(`${this.apiURL}start/${instanceId}`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    stopInstance(instanceId: string) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        // console.log(userDetails);
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token);
        // let httpHeaders = new HttpHeaders()
        //     .set('Content-Type','application/json');
        return this.http.get<any>(`${this.apiURL}stop/${instanceId}`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getInstancesById(instanceId: string) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        // console.log(userDetails);
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token);
        return this.http.get<any>(`${this.apiURL}instances/${instanceId}`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getInstancesLogs() {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');
        const model = {
            method: 'GetAll',
            instanceId: 'i-060e6cbfbf7554e40'
        };
        return this.http.post<any>(`${this.apiURL}GetLogs`, model, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getLogsByEmail(instanceId) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');
        const model = {
            MethodName: 'GetByEmailId',
            instanceId: instanceId,
            Email: localStorage.getItem('ec2currentUsername')
        };
        return this.http.post<any>(`${this.apiURL}GetLogs`, model, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getDetailedInstanceById(instanceId: string) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        // console.log(userDetails);
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token);
        return this.http.get<any>(`${this.apiURL}instances/detailed/${instanceId}`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getInstanceLogsByInstanceId(instanceId) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');
        const model = {
            MethodName: 'GetByEmailId',
            instanceId: instanceId,
            Email: localStorage.getItem('ec2currentUsername')
        };
        return this.http.post<any>(`${this.apiURL}GetInstanceLogs`, model, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    createSchedule(model) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');
        return this.http.post<any>(`${this.apiURL}ScheduleInstance`, model, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getSchedules() {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.post<any>(`${this.apiURL}GetSchedules`, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }

    getWorkProcesses(id) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.get<any>(`${this.webserviceURL}WPTable/ABAPGetSystemWPTable/${id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getSAPSystemInstanceList(Id) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.get<any>(`${this.webserviceURL}WPTable/GetSystemInstanceList/${Id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    startSAPSystemInstance(instanceId) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.get<any>(`${this.webserviceURL}WPTable/StartSystem/${instanceId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    stopSAPSystemInstance(instanceId) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.get<any>(`${this.webserviceURL}WPTable/StopSystem/${instanceId}`)
            .pipe(map(data => {
                return data;
            }));
    }

    getCostsAndUsage(id: number) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');

        return this.http.get<any>(`${this.webserviceURL}CostExplorer/GetCosts/${id}`)
            .pipe(map(data => {
                return data;
            }));
    }

    deleteSchedule(model) {
        const userDetails = this.authService.getAuthenticatedUser().subscribe(result => {
            this.token = result;
        });
        const httpHeaders = new HttpHeaders()
            .set('authorization', this.token)
            .set('content-type', 'application/json');
        return this.http.post<any>(`${this.apiURL}DeleteSchedule`, model, { headers: httpHeaders })
            .pipe(map(data => {
                return data;
            }));
    }
}
