import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment.prod';
import { JwtHelperService } from '@auth0/angular-jwt';



const poolData = {
  // UserPoolId: 'ap-south-1_EYJiI5uP2',
  // ClientId: '7qf17q7b8bigmjb76je25pnnih'

  // SabbasTest
  // UserPoolId: 'eu-west-1_nWxZN6pV0',
  // ClientId: '3skhpa6lq3f66elj70639op4od'

  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};

const userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {
  cognitoUser: any;
  cognitoUserSession: any;
  constructor(private jwtHelperService: JwtHelperService) { }

  Login(email, password) {

    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    return Observable.create(observer => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          // User authentication was successful
          // console.log(result);
          localStorage.setItem('idToken', result.getIdToken().getJwtToken().toString());
          observer.next(result);
          observer.complete();
        },

        onFailure: function (err) {
          console.log(err);
          observer.error(err);

          // User authentication was not successful
        },

        // mfaRequired: function(codeDeliveryDetails) {
        //     // MFA is required to complete user authentication.
        //     // Get the code from user and call
        //     cognitoUser.sendMFACode(mfaCode, this)
        // },

        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          console.log('Temporary Password resetted');
          // Get these details and call
          cognitoUser.completeNewPasswordChallenge(authenticationData.Password, userAttributes, this);
        }
      });

    });
  }

  Register(email, password, mobile) {

    const attributeList = [];

    const attribEmail = new CognitoUserAttribute({
      Name: 'email',
      Value: email
    });
    const attribPhone = new CognitoUserAttribute({
      Name: 'phone_number',
      Value: mobile
    });
    attributeList.push(attribEmail);
    attributeList.push(attribPhone);
    return Observable.create(observer => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          console.log('signUp error', err);
          observer.error(err);
        }

        this.cognitoUser = result.user;
        console.log('signUp success', result);
        observer.next(result);
        observer.complete();
      });
    });

  }

  confirmAuthCode(code) {
    const user = {
      Username: this.cognitoUser.username,
      Pool: userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          console.log(err);
          observer.error(err);
        }
        console.log('confirmAuthCode() success', result);
        observer.next(result);
        observer.complete();
      });
    });
  }

  forgotPassword(unauthenticatedusername) {
    const user = {
      Username: unauthenticatedusername,
      Pool: userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          observer.next(data);
          observer.complete();
          // successfully initiated reset password request
          console.log('CodeDeliveryData from forgotPassword: ' + data);
        },
        onFailure: function (err) {
          observer.error(err);
          // alert(err.message || JSON.stringify(err));
        },
        // Optional automatic callback
        // inputVerificationCode: function(data) {
        //     console.log('Code sent to: ' + data);
        //     var verificationCode = prompt('Please input verification code ' ,'');
        //     var newPassword = prompt('Enter new password ' ,'');
        //     cognitoUser.confirmPassword(verificationCode, newPassword, {
        //         onSuccess() {
        //             console.log('Password confirmed!');
        //         },
        //         onFailure(err) {
        //             console.log('Password not confirmed!');
        //         }
        //     });
        // }
      });
    });
  }

  confirmForgotPasswordCode(username, code, newPassword) {
    const user = {
      Username: username,
      Pool: userPool
    };

    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          console.log('Password confirmed!');
          observer.next('Password confirmed!');
          observer.complete();
        },
        onFailure(err) {
          observer.error(err);

          console.log('Password not confirmed!');
        }
      });
    }

    );
  }

  getAuthenticatedUser(): Observable<string> {
    // returning user token
    const cognitoUserS = userPool.getCurrentUser();
    if (cognitoUserS != null) {
      return Observable.create(observer => {
        cognitoUserS.getSession(function (err, result) {
          if (result) {
            // return result.getIdToken().getJwtToken();
            observer.next(result.getIdToken().getJwtToken());
          }
        });
      });
    }
  }

  isLoggedIn() {
    return userPool.getCurrentUser();
  }

  logout() {
    this.isLoggedIn().signOut();
    this.cognitoUser = null;
    this.cognitoUserSession = null;
  }

  getLoggedUser(): Observable<boolean> {
    // returning user token
    const cognitoUserS = userPool.getCurrentUser();
    if (cognitoUserS != null) {
      this.getSession();
      // const roles = this.getRole();
      // const boolCheck = this.getUserAttributes().subscribe(result => { });
      // const updCheck = this.updateUserAttributes();
      // this.hasAdminAccess();
      return Observable.create(observer => {
        cognitoUserS.getSession(function (err, result) {
          if (result) {
            // return result.getIdToken().getJwtToken();
            // observer.next(result.getIdToken().getJwtToken());
            observer.next(true);
          }
        });
      });
    } else {
      return Observable.create(observer => {
        observer.next(false);
      });
    }
  }

  getUserAttributes(): Observable<any> {
    // returning user token
    // const cognitoUserS = userPool.getCurrentUser();
    if (this.cognitoUserSession != null) {
      return Observable.create(observer => {

        this.cognitoUserSession.getUserAttributes(function (attrErr, attrResult) {
          if (attrErr) {
            alert(JSON.stringify(attrErr));
          }
          for (let i = 0; i < attrResult.length; i++) {
            if (attrResult[i].getName() === 'custom:Role') {
              localStorage.setItem('role', attrResult[i].getValue().toString());
            }
          }
        });
      });

    }
  }

  updateUserAttributes() {
    // returning user token
    // const cognitoUserS = userPool.getCurrentUser();
    if (this.cognitoUserSession != null) {

      const attributeList = [];
      const attribute = {
        Name: 'custom:Role',
        Value: 'Administrator'
      };
      const roleAttrib = new CognitoUserAttribute(attribute);
      attributeList.push(roleAttrib);

      this.cognitoUserSession.updateAttributes(attributeList, function (err, result) {
        if (err) {
          alert(err);
          return;
        }
        console.log('call result: ' + result);
      });

    }
  }

  getSession() {
    const congnitoUser = userPool.getCurrentUser();
    if (congnitoUser != null) {
      congnitoUser.getSession((err, session) => {
        if (err) {
          this.logout();
          return;
        }
        this.cognitoUserSession = congnitoUser;
      });
    } else {
      this.logout();
    }
  }

  getRole() {
    const userToken = localStorage.getItem('idToken');
    // get token from local storage or state management
    // const token = user.accessToken;
    if (userToken) {
      const decodeToken = this.jwtHelperService.decodeToken(userToken);
      return decodeToken['cognito:groups'];
    }
  }

  hasAdminAccess() {
    let access = false;
    const roles = this.getRole();
    if (roles) {
      const findAdmin = roles.find(x => x === 'Administrator');
      if (findAdmin) {
        access = true;
      }
    }
    return access;
  }

}
