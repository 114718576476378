import { Component, AfterViewInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../services/authorization.service';
declare var $: any;
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
    name: string;
    username: string = localStorage.getItem('ec2currentUsername');
    params: any = {};
    constructor(private modalService: NgbModal,
        private router: Router,
        private auth: AuthorizationService) {

    }

    ngAfterViewInit() {

        const set = function () {
            const width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            const topOffset = 0;
            if (width < 1170) {
                $('#main-wrapper').addClass('mini-sidebar');
            } else {
                $('#main-wrapper').removeClass('mini-sidebar');
            }
        };
        $(window).ready(set);
        $(window).on('resize', set);


        $('.search-box a, .search-box .app-search .srh-btn').on('click', function () {
            $('.app-search').toggle(200);
        });

        $('.dropdown-menu').click(function(e) {
            e.stopPropagation();
        });

        $('body').trigger('resize');

        this.params = {'email': this.username};
    }

    logout() {
        localStorage.removeItem('ec2currentUser');
        localStorage.removeItem('ec2currentUsername');
        this.auth.logout();
        this.router.navigate(['login']);
    }
}
